<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Stores</h4>
                  <div class="d-flex gap-1">
                    <button class="btn btn-purple" data-toggle="modal" data-target="#confirm_push"
                      @click="pausingOrders = false">
                      <span>
                        <span v-if="updatingVendors">
                          <span v-html="menuspinner"></span>
                        </span>
                        Update Status
                      </span>
                    </button>

                    <router-link :to="{ name: 'createstore' }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New Store
                      </button>
                    </router-link>
                  </div>

                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchStores()" aria-required="false"
                          autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."
                          class="form-control">
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control mt-1" @change="searchStores()" v-model="storeStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>

                  </div>
                </div>


              </div>
              <hr>
            </div>






            <div class="col-lg-12">
              <div class="filters" id="filter_bar" v-if="(user.role.id == 1 || user.role.id == 2) && storeIds.length">

                <span class="btn-filter mt-1">
                  <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate=true>
                </span>

                <button class="btn-filter mr-1 mt-1">
                  {{ storeSelected > 1 ? storeSelected + ' stores' : storeSelected + ' store' }} selected
                </button>

                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('publish', 1)">
                  <i class='fa fa-check' style="color:green;"></i>
                  <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span v-else>
                    publish</span> </button>
                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('unpublish', 2)">
                  <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                  <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span> <span v-else>
                    Unpublish</span> </button>
                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('trash', 4)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span v-if="methodSpinner == 'trash'">
                    <span v-html="spinner"></span>
                  </span>
                  <span v-else> Trash</span>
                </button>
              </div>
              <div class="dash_card_body pt-0">
                <div class="table_section storeList">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th class="sticky" width="5px">
                          <input v-model="selectAllStores" type="checkbox" @click="checkItems('check')">
                        </th>
                        <th class="sticky" scope="col">Name</th>
                        <th class="sticky" scope="col">Status</th>
                        <th class="sticky" scope="col">ID</th>
                        <th class="sticky" scope="col" v-for="channel in channelNames" :key="channel">
                          {{ channel }}
                        </th>

                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="store in stores" :key="store.id">
                        <td>
                          <input type="checkbox" :id="'store_' + store.id" @click="selectStore(store.id)">
                        </td>
                        <td> {{ store.branch_name }} </td>
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button">
                              <span v-if="store.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="store.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="store.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="user.role.id == 1 || user.role.id == 2" type="button"
                              class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown"
                              :id="'dropdownMenuButton' + store.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + store.id">
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 4)" href="#">Trash</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          {{ store.id }}
                        </td>
                        <td v-for="channel in channelNames" :key="channel" class="col-width">
                          <template v-if="store.channelStatusMap[channel]">
                            <p>
                              <strong>Status: </strong>
                              <span :class="classOfStatusText(store, channel)">
                                {{ store.channelStatusMap[channel]?.status ?? "N/A" }}
                              </span>
                            </p>
                            <div v-if="!store.channelStatusMap[channel].is_online">
                              <p>
                                <strong>Offline Reason: </strong>
                                {{ store.channelStatusMap[channel]?.offline_reason ?? "N/A" }}
                              </p>
                              <p>
                                <strong>Offline Until: </strong>
                                <template v-if="store.channelStatusMap[channel].offline_until">
                                  {{ new Date(store.channelStatusMap[channel].offline_until).toLocaleString() }}
                                </template>
                                <template v-else>
                                  N/A
                                </template>
                              </p>
                            </div>
                            <div v-else>
                              <p>&nbsp;</p>
                            </div>
                            <div class="icon-wrapper" v-if="store.channelStatusMap[channel].status !== 'UNKNOWN'">
                              <template v-if="channel !== 'POSMGR'">
                                <button v-if="store.channelStatusMap[channel].is_online" type="button"
                                  class="btn btn-sm btn-outline-dark" data-toggle="modal" data-target="#confirm_push"
                                  @click="pausingOrders = true; selectSingleStore(store.id, channel)">
                                  <span class="fa fa-pause icon"></span>
                                </button>
                                <button v-else type="button" class="btn btn-outline-dark btn-sm"
                                  @click="selectSingleStore(store.id, channel); updateVendorStatus()">
                                  <span class="fa fa-play icon"></span>
                                </button>
                              </template>
                              <button v-else type="button" class="btn btn-outline-dark btn-sm"
                                @click="selectSingleStore(store.id, channel); updateVendorStatus()">
                                <span class="fa fa-refresh icon"></span>
                              </button>
                            </div>
                          </template>
                        </td>
                      </tr>

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="confirm_push" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update store status on third party channels</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="channel_select">
              <div class="form-group">
                <label>Channel: <small>(updates all if no channel is selected)</small></label>
                <Multiselect v-model="selectedChannels" :value="selectedChannels" mode="tags"
                  placeholder="Select Channel" track-by="name" label="name" :close-on-select="true" :searchable="true"
                  :options="channelNames">
                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                    <div class="multiselect-tag is-user">
                      {{ option.name }}
                      <span v-if="!disabled" class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                      </span>
                    </div>
                  </template>
                </Multiselect>
              </div>
            </div>
            <div class="branch_area">
              <div class="form-group">
                <label>Pause Until: </label>
                <input class="form-control" type="datetime-local" v-model="selectedPauseDuration"
                  :min="minimumDateLocal" />
              </div>
            </div>
            <div class="pause_reason">
              <div class="form-group">
                <label>Pause Reason: </label>
                <textarea class="form-control" type="text" v-model="selectedReason" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="updateVendorStatus()" v-if="!pausingOrders">
              <span>
                <span v-if="updatingVendors">
                  <span v-html="menuspinner"></span>
                </span>
                Accept Orders
              </span>
            </button>
            <button type="button" class="btn custon_orange " @click="updateVendorStatus(true)">
              <span>
                <span v-if="updatingVendors">
                  <span v-html="menuspinner"></span>
                </span>
                Pause Orders
              </span>
            </button>
            <button type="button" id="close_menupush" class="btn btn-light" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    Pagination,
    Multiselect,
  },
  data() {
    let minimumDateLocal = new Date()
      .toLocaleString('sv-SE')
    minimumDateLocal = minimumDateLocal
      .slice(0, minimumDateLocal.lastIndexOf(':'))
      .replace(' ', 'T')
    return {
      channelNames: [],
      selectedChannels: [],
      stores: [],
      loading: false,
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: this.$store.state.storesRecordsPerPage,
      enterpageno: '',
      storeIds: [],
      storeStatus: '',
      methodSpinner: null,
      debounce: null,
      spinner: "<i class='fa fa-spinner fa-spin '></i>",
      search: '',
      ipnLoading: false,
      updatingVendors: false,
      pausingOrders: false,
      selectedPauseDuration: null,
      selectedReason: "",
      selectAllStores: false,
      // storeIds: [],
      minimumDateLocal,
      menuspinner: "<i class='fa fa-spinner fa-spin '></i>",
    }
  },
  computed: {
    storeSelected() {
      return this.storeIds?.length || 0
    },
  },
  created: async function () {
    await Promise.all([
      this.getBranches(),
      this.getThirdParties(),
    ])
  },
  methods: {
    async updatePaymentIpn(storeId) {
      try {
        this.ipnloading = storeId
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = `${this.dashBaseUrl}/update-store-ipn/${storeId}`;
        const resp = await this.axios.post(url, {}, config)
        if (resp.status == 200) {
          this.ipnloading = false
          toast.success("ipn updated successfully")
        }
      } catch (error) {
        this.ipnloading = false
        if (error.response.status === 400) {
          let errormsgs = '';
          for (let x = 0; x < error.response.data.errors.length; x++) {
            errormsgs = error.response.data.errors[x] + ','
          }
          toast.error(errormsgs)
        } else if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
    },
    checkItems(type) {
      const uncheck = document.querySelector('#uncheck')
      if (uncheck) { uncheck.indeterminate = true; }

      if (type === 'check') {
        if (this.selectAllStores) {
          for (const store of Object.values(this.stores)) {
            if (!this.storeIds.includes(store.id)) {
              this.storeIds.push(store.id);
            }
          }
        } else {
          this.storeIds = []
        }
      } else {
        this.storeIds = []
      }

    },
    searchStores() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getBranches();
      }, 1000)
    },
    /**
    * @param {string} storeId
    */
    selectStore(storeId) {
      const idx = this.storeIds.includes(storeId)
      if (idx === -1) {
        this.storeIds.push(storeId);
      } else {
        this.storeIds.splice(idx, 1)
      }
    },
    bulkStatusUpdate(method, status) {
      this.updateStatus(this.storeIds, method, status);
    },
    updateStatusPerItem(storeId, status) {
      const storeIdsArr = [];
      if (storeIdsArr.includes(storeId) === false) {
        storeIdsArr.push(storeId)
      }
      this.updateStatus(storeIdsArr, '', status)
    },
    updateStatus(storeIdArr, method, status) {
      if (storeIdArr.length > 0) {
        this.methodSpinner = method;
        if (method === 'publish' && status === false) {
          this.methodSpinner = 'unpublish'
        }
        const payload = {
          "merchant_id": parseInt(this.merchantID),
          "ids": storeIdArr,
          "status": status
        };
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = this.baseUrl + "/branch/status";
        this.axios.patch(url, payload, config).then((response) => {
          this.storeIds = [];
          if (response.status === 200) {
            this.methodSpinner = null;
            this.getBranches();
          }
        }).catch((error) => {
          this.methodSpinner = null;
          this.storeIds = [];
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        });
      }
    },
    async getBranches() {
      // const url = `${this.baseUrl}//branch?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;

      let url = `${this.baseUrl}/branch/channel_status`;
      url = new URL(url);
      url.searchParams.set('page', this.page);
      url.searchParams.set('size', this.recordsPerPage);
      if (this.storeStatus !== '') {
        url.searchParams.set('status', this.storeStatus);
      }
      if (this.search !== '') {
        url.searchParams.set('query', this.search);
      }
      url.searchParams.set('merchant_id', this.merchantID);

      this.loading = true;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.stores = response.data.data.map(store => {
            return {
              ...store,
              channelStatusMap: store.channel_statuses.reduce((acc, st) => {
                acc[st.channel] = st
                return acc
              }, {}),
            }
          });
          this.storeIds = []
          this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
          this.totalRecords = response.data.total
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      })

    },
    onPageChange(page) {
      this.storeIds = []
      this.selectAllStores = false;
      this.page = page
      this.getBranches();
    },
    onChangeRecordsPerPage() {
      const payload = {
        "type": "stores",
        "size": this.recordsPerPage
      }
      this.$store.commit("setPageLimit", payload)
      // setPageLimit
      this.storeIds = []
      this.selectAllStores = false;
      this.getBranches();
      // ds
    },
    updateStoreStatus(status, branchId) {
      const storeIds = [];
      storeIds.push(branchId)
      //
      const payload = {
        "merchant_id": parseInt(this.merchantID),
        "ids": storeIds,
        "status": status
      };
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + '/branch/publish'
      this.axios.patch(url, payload, config).then((response) => {
        if (response.status === 200) {
          this.getBranches();
        }
      })
        .catch((error) => {
          this.spinner = false;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
      //
    },
    selectSingleStore(branchId, channel) {
      this.storeIds = [branchId]
      this.selectedChannels = [channel]
    },
    updateVendorStatus(pauseOrders = false) {
      this.updatingVendors = true;

      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };

      let reason = ""
      let pauseOrdersUntil = null
      if (pauseOrders) {
        try {
          pauseOrdersUntil = new Date(this.selectedPauseDuration || '').toISOString();
        } catch (_e) {
          toast.error(`Please set a valid "Pause Until" time before pausing orders.`);
          return;
        }

        if (!this.selectedReason) {
          toast.error("Please provide a reason before pausing orders.");
          return
        }

        reason = this.selectedReason
      }

      const payload = {
        branches: this.storeIds,
        channels: this.selectedChannels,
        // eslint-disable-next-line
        pause_orders: pauseOrders,
        // eslint-disable-next-line
        pause_orders_until: pauseOrdersUntil,
        reason: reason,
        // eslint-disable-next-line
        merchant_id: parseInt(this.merchantID),
      }

      console.log(payload)

      const url = this.baseUrl + "/branch/channel_status";
      this.axios.patch(url, payload, config)
        .then((response) => {
          if (response.status === 200) {
            this.getBranches();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
        .finally(() => {
          document.getElementById('close_menupush').click();
          this.updatingVendors = false;
        })
    },
    async getThirdParties() {
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;

      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.channelNames = ['POSMGR']
          const channels = response.data.data
          for (let c = 0; c < channels.length; c++) {
            if (channels[c].status) {
              this.channelNames.push(channels[c].channel_name)
            }
          }
        }
      });
    },
    classOfStatusText(store, channel) {
      if (store.channelStatusMap[channel].status === 'UNKNOWN') {
        return ''
      }

      return store.channelStatusMap[channel].is_online ? 'success-text' : 'error-text'
    },
  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>

<style lang="css">
.gap-1 {
  gap: 0.5rem;
}

.success-text {
  color: #1A8B1F !important;
  font-weight: 900;
}

.error-text {
  color: #C04B2D !important;
  font-weight: 900;
}

.col-width {
  width: 16rem;
}

.icon-wrapper {
  margin-top: 3em;
  padding: 0 0.25rem;
}

.icon-wrapper button {
  border-color: #c1c1c1;
}

.icon-wrapper span.fa.icon {
  width: 2rem;
  padding: 0.2rem;
}

th.sticky {
  background: white;
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
